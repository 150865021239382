import React from 'react'
import ReactHelmet from 'react-helmet'

import favicon from '../../static/favicon.ico'

const Head = () => (
  <ReactHelmet title="Anton Halim">
    <html lang="en" amp />
    <meta name="description" content="Anton Halim Personal Site" />
    <link rel="shortcut icon" href={favicon} />
  </ReactHelmet>
)

export default Head

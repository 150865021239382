import React, { Fragment } from 'react'

import Head from './Head'
import '../style/index.css'

export default function Layout({ children }) {
  return (
    <Fragment>
      <header className="relative m-8 inline-block">
        <a href="/" className="font-bold text-2xl tracking-wide border-grey">
          AH
        </a>
        <div className="absolute -top-2 -left-2 w-8 h-8 bg-indigo-300 rounded-full mix-blend-multiply filter blur animate-blob"></div>
        <div className="absolute -bottom-4 -left-0 w-8 h-8 bg-blue-400 rounded-full mix-blend-multiply filter blur animate-blob animation-delay-3000"></div>
        <div className="absolute top-0 -right-2 w-8 h-8 bg-orange-400 rounded-full mix-blend-multiply filter blur animate-blob animation-delay-2000"></div>
      </header>
      <section className="m-auto p-12 w-full h-full max-w-5xl">
        <Head />

        {children}
      </section>
    </Fragment>
  )
}
